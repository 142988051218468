export const styles = {
    backGroundColor: "gray.900",
    backGroundColorHeader: "gray.700",
    containers:{
        position: 'absolute'
    },
    button: {
        variant: 'link',
        colorHover: 'purple.300',
        color: 'purple.500',
        fontSize: '1vw'
    },
    text:{
        color: 'gray.400'
    }
}
import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetAllArticoliQuery } from "../redux/articolo-api";
import ArticoloItem from "../components/articolo";
import { styles } from "../styles";
import { Header } from "../components/header";
import { useLazyGetUserQuery } from "../redux/auth-api";
import {
  useSavePreferenciesMutation,
  userFavouriteRequest,
} from "../redux/user-api";
import Articolo from "../interfaces/articolo";
import LocalStorageService from "../context/authService";
import { useToast } from "@chakra-ui/react";
//import { useGetAllArticoliQuery } from "../../redux/api/articoli-api";

function Homepage() {
  const [getUser, getUserData] = useLazyGetUserQuery();

  const localStorageService = LocalStorageService.prototype._getService();

  const [savePreference, mutation] = useSavePreferenciesMutation();

  const [user, setUser] = useState(localStorageService._getAuthData());

  const toast = useToast();

  async function loadUserData() {
    const response = await getUser();
    setUser(response.data);
    if (response.data != undefined) {
      localStorageService._setAuthData(response?.data?.user);
    }
    return response;
  }

  useEffect(() => {
    if (!user) {
      loadUserData();
    }
  }, []);

  const handleSaveFavourites = async (articolo: Articolo) => {
    const user = localStorageService._getAuthData();
    if (user) {
      const request: userFavouriteRequest = {
        articolo: articolo,
        user: user?.id,
      };
      const response = await savePreference(request);
    } else {
      toast({
        title: "Login non effettuato",
        description:
          "Effettua il login per salvare un articolo fra i preferiti!",
        status: "warning",
        position: "bottom-right",
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const { data } = useGetAllArticoliQuery(100);
  return (
    <Box backgroundColor={styles.backGroundColor} w={"100%"} h={"100%"}>
      <Header section={"Home"} user={user} />
      <Box
        backgroundColor={styles.backGroundColor}
        w={"90%"}
        h={"100%"}
        mx={"auto"}
        mt={"30px"}
        mb={"30px"}
      >
        <SimpleGrid
          gridTemplateColumns="repeat(3, 1fr)"
          columns={{ base: 1, md: 2, lg: 3, xl: 5 }}
          gap={6}
        >
          {data?.map((item) => {
            return (
              <ArticoloItem
                section="Home"
                onSavePreferencies={handleSaveFavourites}
                key={item._id}
                data={data ? item : null}
              />
            );
          })}
        </SimpleGrid>
      </Box>
    </Box>
  );
}

export default Homepage;

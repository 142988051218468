import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "../interfaces/user";
import Articolo from "../interfaces/articolo";
import { BASE_URL } from "./baseUrl"
export const userApi = createApi({
  reducerPath: "apiUser",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL ,
  }),
  endpoints: (builder) => ({
    savePreferencies: builder.mutation<boolean, userFavouriteRequest>({
      query: (queryArgs) => {
        return {
          url: "user/add-favourite",
          body: queryArgs,
          method: "POST",
        };
      },
    }),
    getPreferiti: builder.query<Articolo[], number>({
      query: (id) => `/user/get-favourites/${id}`,
    }),

    removePreferiti: builder.mutation<boolean, userRemoveFavouriteRequest>({
      query:(queryArgs)=> {
        return { 
         url:"user/remove-favourite",
         body: queryArgs,
         method: "DELETE"
        }}
      
    })
  }),
});

export const {
  useSavePreferenciesMutation,
  useGetPreferitiQuery,
  useLazyGetPreferitiQuery,
  useRemovePreferitiMutation,
} = userApi;

export interface userFavouriteRequest {
  user: any;
  articolo: Articolo;
}


export interface userRemoveFavouriteRequest{
  userId: number,
  articoloId: string
}
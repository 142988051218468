import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { FaFacebook, FaGoogle, FaTwitter } from "react-icons/fa";
import { styles } from "../styles";
import { Header } from "../components/header";
import { BASE_URL } from "../redux/baseUrl";

function Login(props) {
  const [isOnFocus, setIsOnFocus] = useState(false);

  function onFocus() {
    setIsOnFocus(true);
  }

  function onBlur() {
    setIsOnFocus(false);
  }

  function handleGoogleLogin(){
    window.open(`${BASE_URL}/auth/google`, "_self")
  }

  function handleFbLogin(){
    window.open(`${BASE_URL}/auth/facebook`, "_self")
  }
  return (
    <> 
    <Header section={"Login"} user ={null}/>
    <Box
      backgroundColor={styles.backGroundColor}
      w={"100%"}
      h="calc(92vh)"
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={'center'}
      justifySelf={'center'}
      display={'flex'}
    >
      <Card
        background={styles.backGroundColorHeader}
        onMouseEnter={onFocus}
        onMouseLeave={onBlur}
        boxShadow={isOnFocus ? "dark-lg" : "base"}
        maxW="md"
        padding={"10px"}
        border={"1px solid #553C9A"}
        _hover={{ border: "2px solid #805AD5" }}
        mx={"auto"}
        mt={"auto"}
        mb={"auto"}
      >
        <CardHeader>
          <Heading size={'xl'} textAlign={'center'} color={styles.text.color}>Scegli la piattaforma da cui accedere</Heading>
        </CardHeader>
        <CardBody>
          <VStack spacing={6}>
            <Button colorScheme="facebook" leftIcon={<FaFacebook />} onClick={handleFbLogin}>
              Facebook
            </Button>
            <Button colorScheme="twitter" leftIcon={<FaTwitter />}>
              Twitter
            </Button>
            <Button colorScheme="red" leftIcon={<FaGoogle />} onClick={handleGoogleLogin}>
              Google
            </Button>
          </VStack>
        </CardBody>
      </Card>
    </Box>
    </>
  );
}

Login.propTypes = {};

export default Login;

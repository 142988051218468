import { Box, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetAllArticoliQuery } from "../redux/articolo-api";
import ArticoloItem from "../components/articolo";
import { styles } from "../styles";
import { Header } from "../components/header";
import {
  useRemovePreferitiMutation,
  userFavouriteRequest,
  useLazyGetPreferitiQuery,
  userRemoveFavouriteRequest,
} from "../redux/user-api";
import Articolo from "../interfaces/articolo";
import { useSelector } from "react-redux";
import LocalStorageService from "../context/authService";
import { useNavigate } from "react-router-dom";
function Salvati() {
  const [removePreferito, mutation] = useRemovePreferitiMutation();

  const navigate = useNavigate();

  const [getFavourites, resultFavourites] = useLazyGetPreferitiQuery();

  const localStorageService = LocalStorageService.prototype._getService();

  const user = localStorageService._getAuthData();

  useEffect(() => {
    if (user) {
      getFavourites(user.id).then((response) => {
        console.log(response);
      });
    } else {
      handleLogin();
    }
    console.log(user);
  }, []);

  function navigateTo(route: string) {
    navigate((route = route));
  }

  function handleLogin() {
    navigateTo("/login");
  }

  const handleSavePreference = async (articolo: Articolo) => {
    const user = localStorageService._getAuthData();

    const request: userRemoveFavouriteRequest = {
      articoloId: articolo._id,
      userId: user?.id,
    };
    const response = await removePreferito(request);
    getFavourites(user.id);
  };

  return (
    <>
      {resultFavourites.isSuccess && (
        <Box backgroundColor={styles.backGroundColor} w={"100%"} h={"100%"}>
          <Header section={"salvati"} user={user} />
          <Box
            backgroundColor={styles.backGroundColor}
            w={"90%"}
            h={"100%"}
            mx={"auto"}
            mt={"30px"}
            mb={"30px"}
          >
            <SimpleGrid
               h={"100%"}
              gridTemplateColumns="repeat(3, 1fr)"
              columns={{ base: 1, md: 2, lg: 3, xl: 5 }}
              gap={6}
            >
              {resultFavourites.data.map((item) => {
                return (
                  <ArticoloItem
                    section="Salvati"
                    onSavePreferencies={handleSavePreference}
                    key={item._id}
                    data={resultFavourites.data ? item : null}
                  />
                );
              })}
            </SimpleGrid>
          </Box>
        </Box>
      )}

      {!resultFavourites.isError ||
        (resultFavourites.data.length == 0 && <div>NESSUN RISULTATO!</div>)}
    </>
  );
}

export default Salvati;

import { configureStore } from "@reduxjs/toolkit";
import { apiArticoli } from "./articolo-api";
import { apiCommenti} from "./commento-api"; 
import { apiUser } from "./auth-api";
import { userApi } from "./user-api";

export const store = configureStore({
    reducer:{
        [apiArticoli.reducerPath]: apiArticoli.reducer,
        [apiCommenti.reducerPath]: apiCommenti.reducer,
        [apiUser.reducerPath]: apiUser.reducer,
        [userApi.reducerPath]: userApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        apiCommenti.middleware,
        apiArticoli.middleware,
        apiUser.middleware,
        userApi.middleware)
})
 

import { Button } from "@chakra-ui/react";
import { styles } from "../styles";
import { useEffect, useState } from "react";

interface ICustomButtonProps {
    onClick: () => void;
    text: string;
    leftIcon?: any
    isDisabled?: boolean
}

export function CustomButton(props: ICustomButtonProps) {
    const [isHover, setIsHover] = useState(false);

    function setHover() {
        setIsHover(!isHover)
    }
    useEffect(() => {
    }, [isHover])

    return (
        <Button backgroundColor={styles.button.color} _hover={{ bg: styles.button.colorHover }}
            leftIcon={props.leftIcon}
            mr={3} onClick={props.onClick}
            onMouseEnter={setHover}
            onMouseLeave={setHover}
            fontSize={styles.button.fontSize}
            isDisabled={props.isDisabled}>
            {props.text}
        </Button>
    )
}

export function CustomGhostButton(props: ICustomButtonProps) {
    const [isHover, setIsHover] = useState(false);

    function setHover() {
        setIsHover(!isHover)
    }
    useEffect(() => {
    }, [isHover])

    return (
        <>
            <Button color={isHover ? styles.button.colorHover : styles.button.color}
                leftIcon={props.leftIcon}
                onMouseEnter={setHover} variant='link'
                onClick={props.onClick}
                onMouseLeave={setHover}>
                {props.text}
            </Button>
        </>
    )
}
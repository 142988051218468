import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { Commento } from "../interfaces/commento"
import { BASE_URL } from "./baseUrl"
export const apiCommenti = createApi({
    reducerPath: 'apiCommenti', 
    baseQuery: fetchBaseQuery({
        baseUrl:BASE_URL 
    }),
    endpoints:(build)=>({
        getAllCommentiByArticolo: build.query<Commento[], string>({
            query:(idArticolo)=>`/commenti/${idArticolo}`
        })
    })
})

export const { useGetAllCommentiByArticoloQuery } = apiCommenti
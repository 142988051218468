import * as React from "react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Homepage from "./pages/home";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Login from "./pages/login";
import Salvati from "./pages/salvati";

export const App = () => (
    <>
    <div style={{ minHeight: "100vh" }}>

  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </Provider>
  </div>
  </>
);



const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/preferenze",
    element: <Homepage />,
  },

  { path: "/login", element: <Login /> },
  { path: "/salvati", element: <Salvati /> },
]);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "../interfaces/user";
import { BASE_URL } from "./baseUrl"
export const apiUser = createApi({
  reducerPath: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL ,
  }),
  endpoints: (builder) => ({
    getUser: builder.query<any, void>({
      query: () => {
        return {
          url: "/auth/login/success",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        };
      },
    }),
    logout: builder.query<void, void>({
      query: () => {
        return {
          url: "/auth/logout/",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        };
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useLazyLogoutQuery,
  useLogoutQuery,
} = apiUser;

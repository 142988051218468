import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import Articolo from '../interfaces/articolo'
import { BASE_URL } from "./baseUrl"

export const apiArticoli = createApi({
    reducerPath: 'apiArticoli',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL 
    }),
    endpoints:(build)=>({
        getAllArticoli: build.query<Articolo[], number>({
            query:(limit)=>`/articles/find-all/null/${limit}`
        })
    })

})



export const {useGetAllArticoliQuery}= apiArticoli
class LocalStorageService  {
    private constructor() {}
    private static instance: LocalStorageService;

    public  _getService(): LocalStorageService {
        if (!LocalStorageService.instance) {
            LocalStorageService.instance = this;
        }
    
        return LocalStorageService.instance;
    
    }

    public _getAuthData(): any {
        return JSON.parse(localStorage.getItem("authData") || 'null');
    }

    public _setAuthData(newAuthData: any): void {
        localStorage.setItem("authData", JSON.stringify(newAuthData));
    }

    public _clearAuthData(): void {
        localStorage.removeItem("authData");
    }

    public _setSelectedRole(role: any): void {
        localStorage.setItem("role", JSON.stringify(role));
    }

    public _getSelectRole(): string {
        return JSON.parse(localStorage.getItem("role") || 'null');
    }
    public _clearSelectRole(): void {
        localStorage.removeItem("role");
    }
    public _getCurrentUserSettingsKey(): string | null {
        const authData = this._getAuthData();
        return authData ? `${authData.Username}_settings` : null;
    }

    public _getCurrentUserSettings(): any {
       
        return JSON.parse(localStorage.getItem("currentUserSettingsKey" || '') || 'null');
    }

    public _setCurrentUserSettings(newCurrentUserSettings: any): void {
        
        localStorage.setItem(
            "currentUserSettingsKey" || '',
            JSON.stringify(newCurrentUserSettings)
        );
    }


};

export default LocalStorageService;

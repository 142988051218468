import { Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CustomButton } from "./customButton";
import { Navigator, useNavigate } from "react-router-dom";
import { styles } from "../styles";
import { useLazyLogoutQuery, useLazyGetUserQuery } from "../redux/auth-api";
import LocalStorageService from "../context/authService";
interface IHeaderProps {
  section: string;
  user: any;
}

export function Header(props: IHeaderProps) {
  const navigate = useNavigate();
  const localStorageService = LocalStorageService.prototype._getService();
  const [userData, setUser] = useState(props.user);
  const [logout, resultLogout] = useLazyLogoutQuery();


  useEffect(() => {
    setUser(props.user);
   console.log(userData)
  }, [props.user]);

  async function handleLogout() {
    const result = await logout();
    localStorageService._clearAuthData();
    setUser(null)
    navigateTo("/");
  }

  function navigateTo(route: string) {
    navigate((route = route));
  }

  function handleLogin(){
    navigateTo("/login")
  }

  return (
    <Stack
      backgroundColor={styles.backGroundColorHeader}
      spacing={8}
      direction="row"
      alignItems={"center"}
      justify="center"
      height={"80px"}
    >
      <CustomButton
        isDisabled={props.section == "Home"}
        onClick={() => navigateTo("/")}
        text={"Home"}
      ></CustomButton>
      <CustomButton
        onClick={() => navigateTo("/preferenze")}
        text={"Preferenze"}
      ></CustomButton>
      <CustomButton
        onClick={() => navigateTo("/salvati")}
        isDisabled={props.section == "salvati"}
        text={"Articoli salvati"}
      ></CustomButton>
      {userData && userData != undefined && (
        <CustomButton onClick={handleLogout} text={"Logout"}></CustomButton>
      )}
      {!userData&&(
        <CustomButton
          onClick={handleLogin}
          text={"Login"}
        ></CustomButton>
      )}
    </Stack>
  );
}

import { Box, Button, Collapse, Divider, Heading, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Commento } from "../interfaces/commento";
import { Text } from "@chakra-ui/react";
import {styles} from "../styles";
import { CustomGhostButton } from "./customButton";
interface ICommentiProps {
    commenti: Commento[] | undefined,

}

interface ICommentoProps {
    commento: Commento
}
export function ListCommenti(props: ICommentiProps) {

    return (
        <Box scrollBehavior={'auto'}>
            {props.commenti?.map((commento: Commento) => {
                return (
                    <CommentoBox key={commento._id} commento={commento} />
                )
            })}
        </Box>
    )
}


function CommentoBox(props: ICommentoProps) {
    const { isOpen, onToggle } = useDisclosure();
    const[isHover, setIsHover] = useState(false);

    function setHover(){
        setIsHover(!isHover)
    }

    return (
        <Box padding={'10px'}>
            <Heading color={styles.text.color} as='h6' size='xs'>
                {props.commento.user}:
            </Heading>
            <Text color={styles.text.color} fontSize='sm'>{props.commento.commento}</Text>
            <Divider />
            {props.commento.replies.length > 0 && <CustomGhostButton text={isOpen?"Nascondi Risposte": "Visualizza Risposte"} onClick={onToggle}/>}
            <Collapse in={isOpen} animateOpacity>
                <ListCommenti commenti={props.commento.replies} />
            </Collapse>
        </Box>

    )
}
import React, { useState } from "react";
import Articolo from "../interfaces/articolo";
import { Commento } from "../interfaces/commento";
import { Button, CircularProgress, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { ListCommenti } from "./listCommenti";
import { useGetAllCommentiByArticoloQuery } from "../redux/commento-api";
import { styles } from "../styles";
import { CustomButton } from "./customButton";

interface IModalArticoloProps{
    articolo: Articolo | null
    commenti: Commento | null
    closeModal: any
}
export function ModalArticolo(props: IModalArticoloProps){
    const [isOpen, setIsOpen] = useState(true);

    const {
      data : commenti, 
      isError, 
      isLoading, 
      isFetching, 
      isSuccess} = useGetAllCommentiByArticoloQuery(props.articolo?._id || '');

      console.log(props.articolo)

    return (
      <>
      
    
  
        <Drawer size={'lg'} isOpen={isOpen} onClose={props.closeModal}>
        {isFetching && <CircularProgress isIndeterminate color='green.300' />}
          <DrawerOverlay />
          {true &&
          <DrawerContent>
            <DrawerHeader backgroundColor={styles.backGroundColorHeader}>
                <Text textAlign={'center'} color={styles.text.color}> {props.articolo?.titolo} </Text>
            
       
            </DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody backgroundColor={styles.backGroundColor}>
             <ListCommenti commenti={commenti}/>
            </DrawerBody>
  
            <DrawerFooter backgroundColor={styles.backGroundColor}>
              {/* <Button backgroundColor={styles.button.color} mr={3} onClick={props.closeModal}>
                Close
              </Button> */}
              <CustomButton onClick={props.closeModal} text="Chiudi"/>1
            
            </DrawerFooter>
          </DrawerContent>
}
        </Drawer>
      </>
    )
}

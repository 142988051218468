import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  HStack,
  Heading,
  Spacer,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Articolo from "../interfaces/articolo";
import { Text } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { ChatIcon, LinkIcon, StarIcon } from "@chakra-ui/icons";
import { ModalArticolo } from "./modalArticolo";
import { styles } from "../styles";
import { CustomGhostButton } from "./customButton";

interface IArticoloProps {
  data: Articolo | null;
  section: string;
  onSavePreferencies: (articolo: Articolo) => void;
}

export default function ArticoloItem(props: IArticoloProps) {
  const [isOnFocus, setIsOnFocus] = useState(false);

  const [isOpenModalCommenti, setOpenModalCommenti] = useState(false);

  function onFocus() {
    setIsOnFocus(true);
  }

  function onBlur() {
    setIsOnFocus(false);
  }

  function openModalCommenti() {
    setOpenModalCommenti(!isOpenModalCommenti);
  }

  function handleSaveToFavourite() {
    props.onSavePreferencies(props.data);
  }

  return (
    <Card
      background={styles.backGroundColorHeader}
      onMouseEnter={onFocus}
      onMouseLeave={onBlur}
      boxShadow={isOnFocus ? "dark-lg" : "base"}
      maxW="md"
      padding={"2px"}
      border={"1px solid #553C9A"}
      _hover={{ border: "2px solid #805AD5" }}
    >
      <a href={props.data?.href} target="_blank">
        <CardHeader>
          <Flex>
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              <Box maxH={30}>
                <Heading noOfLines={3} color={styles.text.color} size="sm">
                  {props.data?.titolo}
                </Heading>
              </Box>
            </Flex>
            {isOpenModalCommenti && (
              <ModalArticolo
                articolo={props.data}
                commenti={null}
                closeModal={openModalCommenti}
              />
            )}
          </Flex>
        </CardHeader>

        <CardBody>
          <Flex flexDirection={"row"}>
            <Text color={styles.text.color} align={"left"}>
              {props.data?.fonte}
            </Text>
            <Spacer />
            <Text color={styles.text.color} align={"right"}>
              {props.data?.date.toString()}
            </Text>
          </Flex>
        </CardBody>
        <Image
          objectFit="cover"
          src={props.data?.imgHref}
          alt="Chakra UI"
          width={"100%"}
          height={"100px"}
        />
      </a>
      <CardFooter
        justify="space-around"
        flexWrap="wrap"
        sx={{
          "& > button": {
            minW: "136px",
          },
        }}
      >
        <HStack> 
        <CustomGhostButton
          text={props.section === "Home" ? "Salva" : "Rimuovi"}
          onClick={handleSaveToFavourite}
          leftIcon={<StarIcon />}
        />
        {/* <CustomGhostButton
          text={""}
          onClick={openModalCommenti}
          leftIcon={<ChatIcon />}
        /> */}
        <CustomGhostButton
          text={"Copia link"}
          onClick={() => {navigator.clipboard.writeText(props.data?.href)}}
          leftIcon={<LinkIcon />}
        />
        </HStack>
      </CardFooter>
      
    </Card>
  );

  //    </Grid>
}
